<template>
  <v-card elevation="2" outlined class="mx-auto" max-width="800">
    <v-card-title class="justify-center">
      <v-icon x-large color="blue-grey darken-2">mdi-bus</v-icon>
    </v-card-title>
    <v-card-actions>
      <v-card-text class="text-center">
        Bienvenido al Sistema de apoyo a la medición de indicadores de calidad
        de servicio para Red Metropolitana de Movilidad.
      </v-card-text>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {};
</script>
